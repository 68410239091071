import React from "react"
import pic03 from "../assets/images/louis-reed-pwcKF7L4-no-unsplash.jpg"
import { Link as ScrollLink } from "react-scroll"
import Fade from "react-reveal/Fade"

const Innovation = props => (
  <section
    id="science"
    className="spotlight style2 left inactive"
    style={{ backgroundImage: `url(${pic03})` }}
  >
    <span className="image fit main">
      <img src={pic03} alt="" />
    </span>
    <Fade left big>
      <div className="content">
        <header>
          <h2>Innovation</h2>
          <p>
            Our patented CFS technology is backed up by decades of innovation
            from pioneers in the field of mechanobiology.
          </p>
        </header>
        <ul className="actions">
          <li>
            <a href="/innovation" className="button">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </Fade>
    <ScrollLink
      to="services"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Innovation
