import React from "react"
import Fade from "react-reveal/Fade"
import allen from "../assets/images/headshots/allen.png"
import jeff from "../assets/images/headshots/jeff.png"
import rama from "../assets/images/headshots/rama.png"

const Four = props => (
  <Fade up>
    <section id="team" className="wrapper style1 special fade-up">
      <div className="container">
        <header className="major">
          <h2>An Interdisciplinary Founding Team</h2>
          <p>
            Our collective expertise spans the fields of physics, materials
            science, and physiology.
          </p>
        </header>
        <div className="box alt">
          <div className="row gtr-uniform">
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="image fit">
                <img src={rama} alt="Dr. Ramaswamy Krishnan" />
              </span>
              <h3>Ramaswamy Krishnan, Ph.D.</h3>
              <h4>Founder</h4>
              <h5>Contractile Force Screening</h5>
              <h5>Mechano-pharmacology</h5>
              <h5>Cell and Tissue Mechanics</h5>
              <h4>
                Assistant Professor of Emergency Medicine at Harvard Medical
                School
              </h4>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="image fit">
                <img src={jeff} alt="Dr. Jeffrey Fredberg" />
              </span>
              <h3>Jeffrey Fredberg, Ph.D.</h3>
              <h4>Founder</h4>
              <h5>Traction Force Microscopy</h5>
              <h5>Lung (Patho)physiology</h5>
              <h5>Physics of Oncology</h5>
              <h4>
                Professor of Bioengineering and Physiology, Harvard T.H. Chan
                School of Public Health
              </h4>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="image fit">
                <img src={allen} alt="Dr. Allen Ehrlicher" />
              </span>
              <h3>Allen Ehrlicher, Ph.D.</h3>
              <h4>Founder</h4>
              <h5>Material Physics</h5>
              <h5>Nuclear Mechanotransduction</h5>
              <h5>Biomimetic Material Science</h5>
              <h4>
                Associate Professor of Bioengineering at McGill University,
                Montreal, Canada; Canada Research Chair in Active Mechanobiology
              </h4>
            </section>
          </div>
        </div>
      </div>
    </section>
  </Fade>
)

export default Four
