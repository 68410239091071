import React from "react"
import pic04 from "../assets/images/anastasiia-ostapovych-IMm3o-Kfkf8-unsplash.jpg"
import { Link as ScrollLink } from "react-scroll"
import Fade from "react-reveal/Fade"

const Applications = props => (
  <section
    id="applications"
    className="spotlight style3 right inactive"
    /* TODO: fix iamge scale and position */
    style={{ backgroundImage: `url(${pic04})`, "background-position-y": `60%` }}
  >
    <span className="image fit main bottom">
      <img src={pic04} alt="" />
    </span>
    <Fade right big>
      <div className="content">
        <header>
          <h2>Applications</h2>
          <p>
            Have a massive pool of drug prospects? Screening compounds for the
            next Fountain of Youth? Investigating a mechanical disease?
          </p>
        </header>
        <p>
          Whether identifying cardiac inotropes, anti-fibrotic agents, or
          bronchodilators, contractility force screening (CFS) is focused on the
          key physiological endpoint – contractile force, leading to more
          economical, faster, and relevant pharmaceutical development. The
          possibilities don’t end here; we will work with you to implement CFS
          in your workflow!
        </p>
        <ul className="actions">
          <li>
            <a href="#contact" className="button">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </Fade>
    <ScrollLink
      to="science"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Applications
