import React from "react"
import pic03 from "../assets/images/freestocks-nss2eRzQwgw-unsplash.jpg"
import { Link as ScrollLink } from "react-scroll"
import Fade from "react-reveal/Fade"

const Products = props => (
  <section
    id="products"
    className="spotlight style2 left inactive"
    style={{ backgroundImage: `url(${pic03})` }}
  >
    <span className="image fit main">
      <img src={pic03} alt="" />
    </span>
    <Fade left big>
      <div className="content">
        <header>
          <h2>Products</h2>
          <p>
            You need <strong>direct</strong> measurements. We have the solution.
          </p>
        </header>
        <p>
          Live Cell Technologies Canada fills the growing clinical and
          industrial need for cell force assays by translating a biophysics lab
          technology, Traction Force Microscopy (TFM), to a high-throughput
          methodology, Contractile Force Screening (CFS). We provide end-to-end
          solutions to directly measure cellular contractile forces for your
          cell biology, health, and pharmaceutical research needs. Don’t see
          what you need? Not sure what would work? Let us know! We will work
          with you to customize solutions for your research needs.
        </p>
        <ul className="actions">
          <li>
            <a href="/products" className="button">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </Fade>
    <ScrollLink
      to="applications"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Products
