import React from "react"
import Fade from "react-reveal/Fade"

const Signup = props => (
  <section id="contact" className="wrapper style2 special fade inactive">
    <Fade duration={2500}>
      <div className="container">
        <header>
          <h2>Ready to take your research to the next level?</h2>
          <h2>Leave us your email and we'll be in touch!</h2>
        </header>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSe7GQquqvtz-58CWTQYHp8XiD0-gMBPf4kPFq7LHO7PMhwoBg/viewform?embedded=true"
          width="640"
          height="725"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    </Fade>
  </section>
)
export default Signup
