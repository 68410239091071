import React from "react"
import Fade from "react-reveal/Fade"

const Clients = props => (
  <Fade up>
    <section id="services" className="wrapper style1 special fade-up">
      <div className="container">
        <header className="major">
          <h2>Our Clients</h2>
          <p>
            Our clients' work spans drug discovery, validation, and toxicity
            screening
          </p>
        </header>
        <div className="box alt">
          <div className="row gtr-uniform aln-center">
            <h3>Amgen</h3>

            <h3>Anabios</h3>
            <h3>AstraZeneca</h3>
            <h3>Pfizer</h3>
          </div>
        </div>
      </div>
    </section>
  </Fade>
)

export default Clients
