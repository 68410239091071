import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Banner"
import Mechanobiology from "../components/Mechanobiology"
import Products from "../components/Products"
import Applications from "../components/Applications"
import Innovation from "../components/Innovation"
import Services from "../components/Services"
import Clients from "../components/Clients.js"
import Roster from "../components/Roster.js"
import Signup from "../components/Signup"

class Home extends React.Component {
  render() {
    return (
      <Layout location="/">
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Live Cell Technologies Canada - Home"
          meta={[
            {
              name: "description",
              content: "Miniaturize assays. Maximize impact."
            },
            { name: "keywords", content: "sample, something" }
          ]}
        ></Helmet>
        <Banner />
        <Mechanobiology />
        <Products />
        <Applications />
        <Innovation />
        <Services />
        <Clients />
        <Roster />
        <Signup />
      </Layout>
    )
  }
}

export default Home
