import React from "react"
import plate from "../assets/images/plate.png"
import { Link as ScrollLink } from "react-scroll"
import Fade from "react-reveal/Fade"

const Mechanobiology = props => (
  <section
    id="mechanobiology"
    className="spotlight style1 bottom inactive"
    style={{ backgroundImage: `url(${plate})` }}
  >
    <span className="image fit main">
      <img src={plate} alt="" />
    </span>
    <Fade bottom big>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-4 col-12-medium">
              <header>
                <h2>Mechanobiology</h2>
                <p>Physics matters.</p>
              </header>
              <ul className="actions">
                <li>
                  <a href="/mechanobiology" className="button">
                    Learn More
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-4 col-12-medium">
              <p>
                Biological cells are not static, but exert a broad range of
                forces that are key in healthy physiology. In diverse diseases
                from asthma to cardiovascular disease and cancer metastasis,
                this force landscape is profoundly altered. By measuring these
                forces, we can potentially identify and address these
                pathologies.
              </p>
            </div>
            <div className="col-4 col-12-medium">
              <p>
                While cell contractility and cell biophysics are an emerging
                target of diagnostics and therapeutics in human disease, there
                are no clinical or industrial methods for characterizing the
                forces exerted by the cell. We're leading a paradigm shift
                toward <b>mechanomedicine</b> today, which will define the
                landscape of drug development and safety testing of tomorrow.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
    <ScrollLink
      to="products"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)
export default Mechanobiology
