import React from "react"
import pic01 from "../assets/images/Composite_scalebar_pos88.png"
import { Link as ScrollLink } from "react-scroll"

const Banner = props => (
  <section id="banner">
    <div className="content">
      <header>
        <h2>Contractility is the future</h2>
        <p>
          Miniaturize assays.&nbsp;
          <br />
          Maximize impact.
        </p>
      </header>
      <span className="image">
        <img src={pic01} alt="intro" />
      </span>
    </div>
    <ScrollLink
      to="mechanobiology"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Banner
