import React from "react"
import Fade from "react-reveal/Fade"

const Services = props => (
  <Fade up>
    <section id="services" className="wrapper style1 special fade-up">
      <div className="container">
        <header className="major">
          <h2>Services</h2>
          <p>Beyond the product</p>
        </header>
        <div className="box alt">
          <div className="row gtr-uniform">
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-flask"></span>
              <h3>Customized Assays</h3>
              <p>
                We tailor our platform to suit your needs: 1-, 6-, 12-, 24-,
                48-, 96-well plates for soft substrate studies and contractile
                force screening.
              </p>
            </section>

            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-chart-area"></span>
              <h3>Analysis Software</h3>
              <p>Translate rich contractility data into meaningful insights.</p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-comment"></span>
              <h3>1-on-1 Support</h3>
              <p>
                We're here to help you get the most out of your experiments,
                from the design stage through data analysis.
              </p>
            </section>
          </div>
        </div>
        <footer className="major">
          <ul className="actions special">
            <li>
              <a href="#contact" className="button">
                Contact Us
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </section>
  </Fade>
)

export default Services
